import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThreeDots, BallTriangle, TailSpin } from 'react-loader-spinner';
import { useNavigate, Link } from "react-router-dom";
import { differenceInCalendarDays, isToday, isYesterday } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import OpenAI from 'openai';
import Swal from 'sweetalert2';
import * as pdfJS from 'pdfjs-dist/build/pdf';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; 
import { storage } from '../utils/FirebaseConfig';
import config from '../config.json';
import Toast from '../utils/Toast';
import {AxiosPostRequest, AxiosPostRequestFormdata, PostToWebhook} from '../utils/AxiosPostRequest'; 
import { decodeData, cleanJsonString } from '../utils/String';
import FileIcon from '../utils/FileIcon';
import '../Markdown.css';
import { LuSparkle } from "react-icons/lu";
import { IconContext } from "react-icons";
import SettingsModal from './components/SettingsModal';
import Loader  from './components/Loader';
import { ThemeContext } from '../context/ThemeContext';
import { isMobile } from 'react-device-detect';
import ReactDOMServer from 'react-dom/server';
import AgentsComponent from './components/AgentsComponent';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = ({ setIsAuthenticated }) => {
  const apiKey  = config.apiKey;
  const secretKey = config.secretKey;
  const raiaAsisstantId = config.raiaAssistantId;
  const defaultAgentIcon = config.defaultAgentIcon;
  const siteUrl = config.siteUrl;

  const loginUser = JSON.parse(localStorage.getItem('raia-loginUser'));
  const loginKey = loginUser.loginKey;
  const loginUsername = loginUser.firstName + " " + loginUser.lastName;
  const loginEmail = loginUser.email;
  const loginPhone = loginUser.phone;
  const loginCompany = loginUser.company;
  const loginUserId = loginUser.userid

  const [globalLoading, setGlobalLoading] = useState(false);

  // Windows Height
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [realHeight, setRealHeight] = useState(window.innerHeight);
  const handleResize = () => {
    // setIsMobile(window.innerWidth < 1024);
    setRealHeight(window.innerHeight);
  };

  window.addEventListener('resize', handleResize);

  // Light-Dark Mode
  const { theme, toggleTheme } = useContext(ThemeContext);
     
  useEffect(() => {
    // Change scrollbar color based on theme === 'dark' ?
    const scrollbarColor = theme === 'dark' ? '#111' : '#ddd'; // Example colors
    const thumbColor = theme === 'dark' ? '#333' : '#eee'; // Example thumb colors

    document.documentElement.style.setProperty('--scrollbar-color', scrollbarColor);
    document.documentElement.style.setProperty('--scrollbar-thumb-color', thumbColor);

    if(isShowSettingModal) {
      showSettingModal();
    }    
  }, [theme]);

  // Home Page Slider Setting
   const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: false,
    slidesToScroll: 2,
    variableWidth: true,
    autoplay: false,
    rows: 1,
  };

  // Ask Raia Help
  const selectRaiaHelpAgent = () => {
    // const foundAgent = agents.find(agent => agent.openai_assistant_id === raiaAsisstantId);
    // if (foundAgent) {
    //   handleChangeAgent(foundAgent);
    // } else {
      
    // }

    window.open("https://raia2.gitbook.io/raia2/raia-copilot", "_blank", "noopener,noreferrer");
  }

  // User Menu
  const [isShowUserMenu, setIsShowUserMenu] = useState(false);

  // Admin Mode
  const [adminMode, setAdminMode] = useState(() => {
    return JSON.parse(localStorage.getItem('selectedAdminMode')) || false; 
  });

  // User Setting Modal
  const [isShowSettingModal, setIsShowSettingModal] = useState(false);
  const [themeMode, setThemeMode] = useState(theme === 'dark' ? 'Dark' : 'Light');
  const [isAlwaysAdmin, setIsAlwaysAdmin] = useState(localStorage.getItem('IsAlwaysAdmin') || false);

  useEffect(() => {
    if ((theme === 'dark' && themeMode === 'Light') || (theme === 'light' && themeMode === 'Dark')) {
      toggleTheme();
    }
  }, [themeMode]);

  useEffect(() => {
    localStorage.setItem("IsAlwaysAdmin", isAlwaysAdmin)
  }, [isAlwaysAdmin]);

  const SettingModal = ({ themeMode, setThemeMode, showCheck, setShowCheck, onClickDelete }) => (
    <div className="text-left text-gray-200 mt-4 mb-6">
      <div className="flex justify-between items-center">
        <p className="text-sm text-black dark:text-white">Theme</p>
        <select
          value={themeMode}
          onChange={(e) => setThemeMode(e.target.value)}
          className="bg-transparent text-black dark:text-white rounded p-2 text-sm"
        >
          <option className="text-sm bg-white dark:bg-black">Dark</option>
          <option className="text-sm bg-white dark:bg-black">Light</option>
        </select>
      </div>
      <div className="border-t border-custom-hover-gray5 my-1"></div>

      <div className="flex justify-between items-center mt-4">
        <p className="text-sm text-black dark:text-white">Always default to Admin Mode</p>
        <input
          type="checkbox"
          checked={showCheck}
          onChange={() => setShowCheck(!showCheck)}
          className="form-checkbox text-indigo-500 h-5 w-5"
        />
      </div>
      <div className="border-t border-custom-hover-gray5 my-2"></div>

      <div id="deleteButton" className="flex justify-between items-center mt-4 cursor-pointer" onClick={onClickDelete}>
        <p className="text-sm text-black dark:text-white">Delete Account</p>
      </div>
      <div className="border-t border-custom-hover-gray5 my-2"></div>
    </div>
  );

  const showSettingModal = () => {    
    Swal.fire({
      title: `<h2 class="text-lg ml-4 ${theme === 'dark' ? 'text-white' : 'text-black'}" style="text-align: left;">Settings</h2>`,
      html: ReactDOMServer.renderToString(
        <SettingModal
          themeMode={themeMode}
          setThemeMode={setThemeMode}
          showCheck={isAlwaysAdmin}
          setShowCheck={setIsAlwaysAdmin}
          onClickDelete={handleDeleteAccount}
        />
      ),
      background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1',
      showConfirmButton: false,
      showCloseButton: true,
      width: '500px',
      customClass: {
        popup: 'p-6 rounded-lg',
        title: 'text-lg font-semibold custom-title',
        closeButton: 'custom-close-button'
      },
      didOpen: () => {
        // Reattach event listeners after rendering HTML with ReactDOMServer
        document.querySelector("select").addEventListener("change", (e) => setThemeMode(e.target.value));
        document.querySelector("input[type='checkbox']").addEventListener("change", () => setIsAlwaysAdmin(prev => !prev));

        const deleteAccountDiv = document.getElementById("deleteButton");
        if (deleteAccountDiv) {
          deleteAccountDiv.addEventListener("click", handleDeleteAccount);
        }

        // Apply custom styling to the close button based on theme
        const closeButton = document.querySelector('.swal2-close');
        if (closeButton) {
          closeButton.style.color = theme === 'dark' ? '#FFFFFF' : '#000000';  // White for dark theme, black for light theme
          closeButton.style.fontSize = '20px';  // Optional, adjust size if needed
        }

        closeButton.addEventListener('click', () => {
          setIsShowSettingModal(false);
          Swal.close();  // Close the modal
        });
      },
    });
  }

  // SignOut
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {    
    Swal.fire({
      title: `<h2 class="text-lg ${theme === 'dark' ? 'text-white' : 'text-black'}">Are you sure you want to delete your account?</h2>`,
      icon: null,
      background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1', // Set background color based on dark mode
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'bg-red-500 rounded-xl hover:bg-red-700  text-sm px-2 mb-4 mr-8', 
        cancelButton: 'bg-gray-500 rounded-xl  hover:bg-gray-300  text-sm px-2 mb-4',
        popup: 'p-2', // Full width on mobile, smaller on larger screens
      },
    })
    .then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('raia-loginUser'); // Replace with your specific keys
        localStorage.removeItem('selectedThread');
        localStorage.removeItem('selectedAgent');
        setIsAuthenticated(false);
        navigate('/');
      }
    });
  };

  const handleSignout = async () => {    
    Swal.fire({
      title: `<h2 class="text-lg ${theme === 'dark' ? 'text-white' : 'text-black'}">Are you sure you want to sign out?</h2>`,
      icon: null,
      background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1', // Set background color based on dark mode
      showCancelButton: true,
      confirmButtonText: 'Sign out',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'bg-red-500 rounded-xl hover:bg-red-700  text-sm px-2 mb-4 mr-8', 
        cancelButton: 'bg-gray-500 rounded-xl  hover:bg-gray-300  text-sm px-2 mb-4',
        popup: 'p-2', // Full width on mobile, smaller on larger screens
      },
    })
    .then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('raia-loginUser'); // Replace with your specific keys
        localStorage.removeItem('selectedThread');
        localStorage.removeItem('selectedAgent');
        setIsAuthenticated(false);
        navigate('/');
      }
    });
  };

  // Sidebar
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [originThreadList, setOriginThreadList] = useState([]);
  const [threadList, setThreadList] = useState([]);
  const [searchThreadKey, setSearchThreadKey] = useState('');
  const [categorizedThreads, setCategorizedThreads] = useState({
    today: [],
    yesterday: [],
    previous7Days: [],
    past30Days: [],
    older: [],
  });  

  const getThreadsList = async () => {
    if (!selectedAgent) {
      return;
    }
    setGlobalLoading(true);

    const data = {
      'APIKEY': selectedAgent.apikey,
      'SECRETKEY': secretKey,
      'loginKey': loginKey,
      'assistant_id': selectedAgent.openai_assistant_id,
      'AdminMode': adminMode ? 1: 0
    };

    try {
      const response = await AxiosPostRequest(`${siteUrl}/api/getThreads.cfm`, data);

      const filteredResponse = Array.isArray(response)
        ? response.filter(thread => thread.message !== "")  // Filter out empty messages
        : [];

      setSearchThreadKey("")
      setOriginThreadList(filteredResponse);  
      setThreadList(filteredResponse);

      const categorized = categorizeThreadsByDate(filteredResponse);
      setCategorizedThreads(categorized);

      setGlobalLoading(false);
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
      setGlobalLoading(false);
    }
  }  

  useEffect(() => {
    if(searchThreadKey === "") {
      setThreadList(originThreadList)
    } else {
      const filteredThreads = originThreadList.filter((thread) =>
        thread.message.toLowerCase().includes(searchThreadKey.toLowerCase()) ||
        thread.message.toLowerCase().includes(searchThreadKey.toLowerCase())
      );

      setThreadList(filteredThreads)
    }
  }, [searchThreadKey]);  

  useEffect(() => {
    const categorized = categorizeThreadsByDate(threadList);
    setCategorizedThreads(categorized);
  }, [threadList]);  
  
  const categorizeThreadsByDate = (threads) => {
    const today = [];
    const yesterday = [];
    const previous7Days = [];
    const past30Days = [];
    const older = [];

    if (threads && threads.length > 0) {
      threads.forEach((thread) => {
        const createdAt = new Date(thread.created_at * 1000); // No need to convert since it's already in milliseconds
        const daysDiff = differenceInCalendarDays(new Date(), createdAt);
        if (isToday(createdAt)) {
          today.push(thread);
        } else if (isYesterday(createdAt)) {
          yesterday.push(thread);
        } else if (daysDiff <= 7) {
          previous7Days.push(thread);
        } else if (daysDiff <= 30) {
          past30Days.push(thread);
        } else {
          older.push(thread);
        }
      });
    }
  
    const sortByCreatedAt = (a, b) => b.created_at - a.created_at; // Sort function for descending order
    return {
      today: today.sort(sortByCreatedAt),
      yesterday: yesterday.sort(sortByCreatedAt),
      previous7Days: previous7Days.sort(sortByCreatedAt),
      past30Days: past30Days.sort(sortByCreatedAt),
      older: older.sort(sortByCreatedAt),
    };
  };

  const renderThreadIcon = (thread) => {
    let score = thread.Score === "" ? 0 : thread.Score;
    let channel = thread.channel;

    const backgroundColor = score > 6
    ? 'bg-green-500'
    : score > 3
    ? 'bg-yellow-500'
    : score > 0
    ? 'bg-red-500'
    : 'bg-gray-500';

    let iconName = 'fas fa-comment-alt';
    if (channel === 'SMS') iconName = 'fas fa-phone-alt';
    else if (channel === 'EMAIL') iconName = 'fas fa-envelope';
    else if (channel === 'CHAT') iconName = 'fas fa-comment-alt';

    if (!adminMode) return null;    
    return (
      <div 
        className={`rounded-full mr-1.5 p-1 ${backgroundColor} inline-flex items-center justify-center text-center`} 
      >
        {/* <i className={`${iconName} m-1.5`} style={{ fontSize: '12px'}}></i> */}
      </div>     
    );
  }

  const renderSideThreads = (threads, title) => (
    <div key={title}>
      <h1 className="p-2 text-xs font-medium text-custom-text-gray">{title}</h1>
      {threads.map((oneThread) => (
        <div
          key={oneThread.thread_id}
          className={`p-2 text-sm font-normal rounded-lg flex justify-between items-center cursor-pointer group  
            ${theme === 'dark' ? 'hover:bg-custom-hover-gray' : 'hover:bg-gray-300' }`}
        >
          {renderThreadIcon(oneThread)}

          {renameThreadId === oneThread.thread_id ? (
            <input
              type="text"
              value={newThreadName}
              onChange={(e) => setNewThreadName(e.target.value)}
              onBlur={() => handleRenameSubmit(oneThread.thread_id)} // Submit on blur
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleRenameSubmit(oneThread.thread_id); // Submit on Enter key
              }}
              className="mr-3 w-full bg-transparent focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-transparent"
              autoFocus
            />
          ) : (           
            <h2 className="truncate mr-2 w-full" onClick={() => openThread(oneThread)} >{oneThread.message}</h2>
          )}
          
          <div 
            className="opacity-30 md:opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-center relative gap-2 cursor-pointer">
            <i
              className="fas fa-ellipsis-h text-md hover:text-custom-hover-gray2"
              style={{ width: '18px', 
                height: '18px', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center'}}
              onClick={() => toggleHistoryDropdown(oneThread.thread_id)}
            ></i>

            {/* Dropdown Menu */}
            {(historyDropdownOpen === oneThread.thread_id) && (
              <div 
                onMouseLeave={() => toggleHistoryDropdown(null)}  // Clear hovered thread
                className={`absolute  text-sm font-normal rounded-lg top-8 right-1 w-40 z-10
                  ${theme === 'dark' ? 'bg-threeoptions-background' : 'bg-gray-300' }`}
              >
                <div
                  onBlur={() => setHistoryDropdownOpen(null)} 
                  className={`p-2 mt-2 m-1 rounded-md cursor-pointer flex items-center gap-3 ${
                    theme === 'dark' ? 'hover:bg-threeoptions-hover' : 'hover:bg-gray-200'
                  }`}
                  onClick={() => handleRename(oneThread.thread_id, oneThread.message)}
                >
                  <i 
                    className="fas fa-pencil-alt icon-md" 
                    style={{ fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  ></i>
                  <span>Rename</span>
                </div>

                <div
                  className={`p-2 m-1 rounded-md cursor-pointer flex items-center gap-3 ${
                    theme === 'dark' ? 'hover:bg-threeoptions-hover' : 'hover:bg-gray-200'
                  }`}
                  onClick={() => handleAddMemory(oneThread.thread_id)}
                >
                  <i className="fas fa-bookmark icon-md"
                     style={{ fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  ></i>
                  <span>Add to Memory</span>
                </div>
                <div
                  className={`p-2 m-1 rounded-md cursor-pointer flex items-center gap-3 ${
                    theme === 'dark' ? 'hover:bg-threeoptions-hover' : 'hover:bg-gray-200'
                  }`}
                  onClick={() => handleDownloadThread(oneThread.thread_id)}
                >
                  <i className="fas fa-download icon-md"
                     style={{ fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></i>
                  <span>Download</span>
                </div>
                <div
                  className={`p-2 m-1 mb-2 rounded-md cursor-pointer flex items-center gap-3 text-delete-color ${theme === 'dark' ? 'hover:bg-threeoptions-hover' : 'hover:bg-gray-200' }`}
                  onClick={() => handleDeleteThread(oneThread.thread_id)}
                >
                  <i className="fas fa-trash-alt icon-md" 
                    style={{ fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></i>
                  <span>Delete</span>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  // Thread Dropdown Menu
  const [historyDropdownOpen, setHistoryDropdownOpen] = useState(null);

  const toggleHistoryDropdown = (threadId) => {
    setHistoryDropdownOpen(historyDropdownOpen === threadId ? null : threadId);
  };

  // Thread Rename
  const [renameThreadId, setRenameThreadId] = useState(null); // State to track which thread is being renamed
  const [newThreadName, setNewThreadName] = useState(''); // State to hold the new name

  const handleRename = (threadId, currentName) => {
    if (isLoading)
      return;

    setRenameThreadId(threadId); // Set the thread being renamed
    setNewThreadName(currentName); // Set the current name as default in the input
  };
  
  const handleRenameSubmit = async(threadId) => {
    toggleHistoryDropdown(null);

    setGlobalLoading(true);
    
    const data = {
      'APIKEY': selectedAgent.apikey,
      'SECRETKEY': secretKey,
      'loginKey': loginKey,
      'thread_id': threadId,
      'thread_name': newThreadName
    };

    try {
      await AxiosPostRequest(`${siteUrl}/api/updateThread.cfm`, data);
      
      setThreadList(prevThreadList =>
        prevThreadList.map(oneThread =>
          oneThread.thread_id === threadId
            ? { ...oneThread, message: newThreadName } // Update the thread message with the new name
            : oneThread
        )
      );
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
    }
    
    setRenameThreadId(null);
  
    setGlobalLoading(false);
  };

  // Thread AddMemory
  const handleAddMemory = async(threadId) => {
    setGlobalLoading(true);

    const data = {
      'APIKEY': selectedAgent.apikey,
      'SECRETKEY': secretKey,
      'loginKey': loginKey,
      'Thread_id': threadId,
      'AddtoMemory': 1
    };

    try {
      const response = await AxiosPostRequest(`${siteUrl}/api/saveMemory.cfm`, data);
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
    }

    setGlobalLoading(false);
  }

  // Thread Download
  const convertToTextFormat = (data) => {
    // Convert the data to the desired format
    const formattedData = data.map(item => {
      const date = new Date(item.created_at * 1000).toLocaleString(); // Convert UNIX timestamp to readable date
      return `${date} \n${item.message}`;
    }).join('\n'); // Join each entry with a newline

    return formattedData;
  };

  const handleDownloadThread = async(threadId) => {
    let messageData;
    if (thread && thread.thread_id === threadId)  {
      messageData = messages;
    } else {
      messageData = await getThreadMessages(threadId);
    }

    if (messageData.length > 0){
      const textContent = convertToTextFormat(messageData);
      const blob = new Blob([textContent], { type: 'text/plain' });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = messageData[0].message + ".txt"; // Save as a .txt file
      link.click();
    }    
  }

  // Thread Delete
  const handleDeleteThreadfromDB = async(threadId) => {
    const data = {
      'APIKEY': selectedAgent.apikey,
      'SECRETKEY': secretKey,
      'loginKey': loginKey,
      'thread_id': threadId,
    };

    try {
      const response = await AxiosPostRequest(`${siteUrl}/api/deleteThread.cfm`, data);
      
      if (response.status === 'success') {
        setThreadList(threadList.filter(oneThread => oneThread.thread_id !== threadId));
      }

    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
    }
  }

  const handleDeleteThreadfromOpenAI = async(threadId) => {
    setGlobalLoading(true);
    
    try {
      openai.beta.threads.del(threadId);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Thread not found. Please check the threadId:', threadId);
      } else {
        console.error('An error occurred while deleting the thread:', error);
      }
    }

    await handleDeleteThreadfromDB(threadId);

    setGlobalLoading(false);
  }

  const handleDeleteThread = async(threadId) => {
    if (isLoading)
      return;
    toggleHistoryDropdown(null);

    Swal.fire({
      title: `<h2 class="text-lg ${theme === 'dark' ? 'text-white' : 'text-black'}">Are you sure you want to delete this thread?</h2>`,
      html: `<p class="text-sm ${theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}">You won\'t be able to revert this!</p>`,
      icon: null,
      background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1', // Set background color based on dark mode
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'bg-red-500 rounded-xl hover:bg-red-700 text-white text-sm px-2 mb-4 mr-6', // Custom delete button
        cancelButton: 'bg-gray-500 rounded-xl hover:bg-gray-300 text-white text-sm px-2 mb-4', // Custom cancel button
        popup: 'p-2', // Full width on mobile, smaller on larger screens
      },
    }) 
    .then((result) => {
      if (result.isConfirmed) {
        if (thread && thread.id === threadId) {
          handleStartNewChat();
        }
        handleDeleteThreadfromOpenAI(threadId);
      }
    });
  };

  // Agents  
  const [agents, setAgents] = useState([]);

  const hasAgentsFetched = useRef(false);

  const [selectedAgent, setSelectedAgent] = useState(() => {
    return JSON.parse(localStorage.getItem('selectedAgent')) || null; 
  });

  const [transferedAgent, setTransferedAgent] = useState(null);

  const handleChangeAgent = (agent) => {    
    // console.log("selected Agent ===>", agent)
    setSelectedAgent(agent);
    setAgentStatus('')
    setTransferedAgent(null)
    setIsDropdownOpen(false);
    setIsAgentDropdownOpen(false)
    handleStartNewChat();
  };

  useEffect(() => {
    if (selectedAgent) {
      if (!selectedAgent.showDisclaimer) {
        showDisclaimer(selectedAgent);
      }
      
      if(selectedAgent.isAdmin !== 1) setAdminMode(false)

      localStorage.setItem('selectedAgent', JSON.stringify(selectedAgent));

      const { starters: conversationStarters, descriptions: conversationStartersDesc } = extractSuggestions(selectedAgent);

      setMobileSuggestions(conversationStarters.slice(0, 2));
      setMobileSuggestionDescs(conversationStartersDesc.slice(0, 2));

      setWebSuggestions(conversationStarters);
      setWebSuggestionDescs(conversationStartersDesc);

      createOpenAI();
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (transferedAgent) {
      const tmpMessage = {
        role: 'user',
        message: "Hello, appropriate agent. Please review the thread and answer my last question that is in your knowldege domain."    
      };

      handleSendMessage(tmpMessage, false, false);
    }
  }, [transferedAgent]);

  // Main Agent Search
  const [isAgentDropdownOpen, setIsAgentDropdownOpen] = useState(false);
  const [searchMainAgentKey, setSearchMainAgentKey] = useState('');
  useEffect(() => {
    setIsAgentDropdownOpen(searchMainAgentKey.length>0)
  }, [searchMainAgentKey])
  
  const filteredMainAgents = agents.filter((agent) =>
    agent.name.toLowerCase().includes(searchMainAgentKey.toLowerCase()) ||
    agent.description.toLowerCase().includes(searchMainAgentKey.toLowerCase())
  );

  // Agent Search
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const [searchAgentKey, setSearchAgentKey] = useState('');
  const filteredAgents = agents.filter((agent) =>
    agent.name.toLowerCase().includes(searchAgentKey.toLowerCase()) ||
    agent.description.toLowerCase().includes(searchAgentKey.toLowerCase())
  );

  useEffect(() => {
    localStorage.setItem('selectedAdminMode', adminMode);

    hasAgentsFetched.current = false;
    handleGetAgents();
    getThreadsList();

  }, [adminMode]); 

  const handleGetAgents = async () => {
    if (hasAgentsFetched.current) {
      return;
    }
    setGlobalLoading(true);

    hasAgentsFetched.current = true; // Set the flag to true

    const data = {
      'APIKEY': apiKey,
      'SECRETKEY': secretKey,
      'loginKey': loginKey,
      'AdminMode': adminMode ? 1: 0
    };
    
    try {
      const response = await AxiosPostRequest(`${siteUrl}/api/getAgents.cfm`, data);
      if (response.Agents && response.Agents.length > 0) {
        const tmpAgents = response.Agents.map(obj => {
          return {...obj, showDisclaimer: false};
        });

        const sortedAgents = tmpAgents.sort((a, b) => {
          if (a.IsPinned === b.IsPinned) {
            // If both are pinned or both are unpinned, sort by name
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          }
          // Otherwise, prioritize pinned agents
          return a.IsPinned ? -1 : 1;
        });

        setAgents(sortedAgents);
      }
    } catch (error) {
      console.error("Error fetching agents:", error);
    }

    setGlobalLoading(false);
  };

  // Agent Disclaimer
  const showDisclaimer  = async (agent) => {
    agents.forEach(obj => {
      if (obj.apikey === agent.apikey) {
        obj.showDisclaimer = true;
      }
    });

    const disclaimerText = agent.disclaimer === '' ? "" : `${agent.disclaimer} \n`
    const formattedDisclaimer = 
      `
        Hello, my name is ${agent.name}.
        ${disclaimerText}
        AI aims to provide helpful responses, but it may occasionally be inaccurate or incomplete. Please verify important information with a qualified expert, as we are not responsible for errors or omissions.
      `;

    Swal.fire({
      title: `<h2 class="text-lg text-left ${theme === 'dark' ? 'text-white' : 'text-black'}"></h2>`,
      background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1', // Set background color based on dark mode
      html: `<p class="${theme === 'dark' ? 'text-white' : 'text-black'} text-left text-sm px-5" style="white-space: pre-line;">${formattedDisclaimer}</p>`, // Display dynamic comment
      confirmButtonText: 'I understand',
      customClass: {
        confirmButton: 'bg-blue-500 rounded-xl hover:bg-blue-700 text-white text-sm px-2 mb-4', // Custom confirm button
        popup: 'p-2', 
      }
    });
  }

  // Agent Suggestions  
  const [mobileSuggestions, setMobileSuggestions] = useState([]);
  const [webSuggestions, setWebSuggestions] = useState([]);
  const [mobileSuggestionDescs, setMobileSuggestionDescs] = useState([]);
  const [webSuggestionDescs, setWebSuggestionDescs] = useState([]);
  const suggestionsToDisplay = isMobile ? mobileSuggestions : webSuggestions;
  const suggestionDescsToDisplay = isMobile ? mobileSuggestionDescs : webSuggestionDescs;

  const extractSuggestions = (agent) => {
    const starters = [];
    const descriptions = [];

    for (let i = 1; i <= 4; i++) {
        const starter = agent[`ConversationStarter${i}`];
        const desc = agent[`ConversationStarter${i}_desc`];

        if (starter) starters.push(starter);
        if (desc) descriptions.push(desc);
    }

    return { starters, descriptions };
  };

  // OpenAI
  const [openai, setOpenAI] = useState(null);

  useEffect(() => {
    if (openai) {
      getThreadsList();
    }
  }, [openai]);

  const createOpenAI = () => {
    const openaiClient = new OpenAI({ 
      apiKey: selectedAgent.openai_api_key,
      dangerouslyAllowBrowser: true,
    });
    setOpenAI(openaiClient);
  };

  // Run
  const saveRun = async (run) => {
    const data = {
      'Run_ID': run.id,
      'Assistant_ID': run.assistant_id,
      'Thread_ID': run.thread_id,      
      'Message_ID': run.id,
      'LoginKey': loginKey,
      'created_at': run.created_at,
      'completed_at': run.completed_at,
      'total_tokens': run.usage.total_tokens,
    };

    try {
      await AxiosPostRequest(`${siteUrl}/api/saveRun.cfm`, data);
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
    }
  }

  // Threds
  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(
      /(\+1)(\d{3})(\d{3})(\d{4})/,
      '$1-$2-$3-$4'
    );
  };

  const renderThreadInfo = (thread) => {
    if (!thread) return null
    else {
      let score = thread.Score === "" ? 0 : thread.Score;
      let channel = thread.channel;

      const backgroundColor = score > 6
      ? 'bg-green-500'
      : score > 3
      ? 'bg-yellow-500'
      : score > 0
      ? 'bg-red-500'
      : 'bg-gray-500';

      let fullName = thread.fname + " " + thread.lname
      fullName = fullName === " " ? "anonymous" : fullName

      let infoName = '';
      if (channel === 'SMS') infoName = formatPhoneNumber(thread.phone) + " via sms";
      else if (channel === 'EMAIL') infoName =  thread.email.toLowerCase() + " via email";
      else if (channel === 'CHAT') infoName = "192.168.65.40" + " via chat";
      else infoName = fullName

      if (isMobile) {
        return (<div 
          className={`inline-flex items-center justify-center text-center text-sm cursor-pointer`}
          onClick={() => setIsShowThreadInfo(true)}
        >
          <i className="fas fa-info cursor-pointer"></i>
        </div>)
      } else {
        return (
        <div 
          className={`inline-flex items-center justify-center text-center text-sm cursor-pointer`}
          onClick={() => setIsShowThreadInfo(true)}
        >
          <div 
            className={`mr-1 rounded-full ${backgroundColor} w-4 h-4`} 
          ></div>
          {/* <span className={`mr-2`}>{fullName}</span> */}
          <span className={`mr-3`}>{infoName}</span>
          <i
            className="fas fa-ellipsis-v cursor-pointer"></i>
        </div>)
      }
    }
  }

  const [isShowThreadInfo, setIsShowThreadInfo] = useState(false);

  const [isThreadAdminMode, setIsThreadAdminMode] = useState(false);

  const toggleThreadAdminMode = () => {
    setIsThreadAdminMode(!isThreadAdminMode);

    handleUpdateThread(!isThreadAdminMode)
  };  

  const handleUpdateThread = async(threadAdminMode) => {
    const data = {
      'APIKEY': selectedAgent.apikey,
      'SECRETKEY': secretKey,
      'loginKey': loginKey,
      'thread_id': thread.thread_id,
      'AdminMode' : threadAdminMode ? 1: 0
    };

    try {
      const response = await AxiosPostRequest(`${siteUrl}/api/updateThreadAI.cfm`, data);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  const renderShowThreadInfo = () => {
    if (!thread) return null

    let score = thread.Score === "" ? 0 : thread.Score;
    let channel = thread.channel;

    const backgroundColor = score > 6
    ? 'bg-green-500'
    : score > 3
    ? 'bg-yellow-500'
    : score > 0
    ? 'bg-red-500'
    : 'bg-gray-500';

    let fullName = thread.fname + " " + thread.lname
    fullName = fullName === " " ? "anonymous" : fullName

    let phoneNumber = thread.phone === "" ? "No Phone Number" : formatPhoneNumber(thread.phone);
    let email = thread.email === "" ? "No Email" : thread.email.toLowerCase();
    let ipAddress = "192.168.65.40";
    let summary = thread.summary;

    let infoName = '';
    if (channel === 'SMS') infoName = phoneNumber;
    else if (channel === 'EMAIL') infoName = email;
    else if (channel === 'CHAT') infoName = ipAddress;
    else infoName = fullName

    return (
      <div
        className={`fixed z-50 top-0 right-0 h-full w-[320px] transform transition-transform duration-500 ease-in-out
        border-l-2  bg-gray-200 dark:border-threeoptions-background
        ${theme === 'dark' ? 'bg-sidebar-background text-white' : 'bg-white text-black'}
        ${isShowThreadInfo ? 'translate-x-0' : 'translate-x-full'}
        `}
      >
        <button
          className="ml-5 mt-6 hover:text-gray-500"
          onClick={() => setIsShowThreadInfo(false)}
        >
          <i className="far fa-arrow-alt-circle-right" style={{ fontSize: '22px' }}></i>
        </button>
  
        <div className="w-full flex flex-col items-start">
          <div 
            className="flex ml-3 gap-3 py-[10px] mt-5 pl-2 w-auto rounded-xl cursor-pointer relative"
          >
            <div className={`flex items-center justify-center w-7 h-7 ${backgroundColor} rounded-full`}>
              <span className="text-lg font-semibold">{score}</span>
            </div>
  
            <h2 className="text-lg font-medium">{fullName} via {channel}</h2>
          </div>
  
          <div className={`w-full p-4 ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'} `}>
            <span className="text-lg font-semibold m-3">Contact details:</span>
  
            <div className="flex items-center m-4">
              <i className="far fa-envelope pr-3"></i>
              <span className="text-sm font-normal">{email}</span>
            </div>
  
            <div className="flex items-center m-4">
              <i className="fas fa-phone-alt pr-3"></i>
              <span className="text-sm font-normal">{phoneNumber}</span>                
            </div>
  
            {channel === 'CHAT' && (
              <div className="flex items-center m-4">
                <i className="fas fa-globe pr-3"></i>
                <span className="text-sm font-normal">{ipAddress}</span>                
              </div>
            )}
          </div>

           {(channel === 'SMS' || channel === 'EMAIL') && (
            <div className="flex items-center ml-6 mt-4 gap-3">
              <span className="text-sm font-medium">Switch Admin Mode</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isThreadAdminMode}
                  onChange={toggleThreadAdminMode}
                />
                <div className="w-11 h-6 bg-gray-400 rounded-full peer-checked:bg-green-500 peer-focus:ring-2 peer-focus:ring-green-300 transition-colors"></div>
                <div className="absolute w-5 h-5 bg-white rounded-full left-0.5 top-0.5 peer-checked:translate-x-5 transition-transform"></div>
              </label>
            </div>
          )}
  
          <span className="m-6 text-sm font-normal block">{summary}</span>          
        </div>
      </div>
    );
  }

  const hasThreadMessagesFetched = useRef(false);

  const [thread, setThread] = useState(() => {
    return JSON.parse(localStorage.getItem('selectedThread')) || null; 
  });

  const createThread = async () => {
    try {
      setAgentStatus('')
      const newThread = selectedAgent.openai_vector_store_id ? await openai.beta.threads.create({        
        tool_resources: {
          file_search: {
            vector_store_ids: [selectedAgent.openai_vector_store_id]  // Ensure this is an array
          }
        }
      }) : await openai.beta.threads.create({})

      newThread.Score = 0
      newThread.fname = loginUser.firstName
      newThread.lname = loginUser.lastName
      newThread.phone = loginUser.phone
      newThread.email = loginUser.email
      newThread.channel = "COPILOT"
      newThread.thread_id = newThread.id

      setIsThreadAdminMode(false);
      hasThreadMessagesFetched.current = true
      setThread(newThread);
      saveThread(newThread);

      return newThread;
    } catch (error) {
      console.error("Error creating thread:", error);
    }
  };

  useEffect(() => {
    if (thread) {
      localStorage.setItem('selectedThread', JSON.stringify(thread));
      setIsThreadAdminMode(false);
      if(!hasThreadMessagesFetched.current){
        getThread(thread.thread_id);
      }

      setIsSidebarOpen(false);
      setIsShowThreadInfo(false);
      setMessage('');
      setRows(1);
    } else {
      localStorage.removeItem('selectedThread');
    }
  }, [thread]);

  const openThread = async (selectedThread) => {
    if (isLoading)
      return;
    if (thread && thread.thread_id === selectedThread.thread_id) 
      return;

    selectedThread.id = selectedThread.thread_id;
    hasThreadMessagesFetched.current = false
    setThread(selectedThread);
  }

  const getThread = async (threadId) => {
    setGlobalLoading(true);

    const threadMessages = await getThreadMessages(threadId);
    setMessages(threadMessages);
    setGlobalLoading(false);
  }

  const saveThread = async (newThread) => {
    const data = {
      'APIKEY': selectedAgent.apikey,
      'SECRETKEY': secretKey,
      'LOGINKEY': loginKey,
      'thread_id': newThread.id,
      'assistant_id': selectedAgent.openai_assistant_id,
      'created_at': newThread.created_at,
      'Userid': loginUserId
    };

    try {
      await AxiosPostRequest(`${siteUrl}/api/saveThread.cfm`, data);
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
    }
  }

  const isValidJSON = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true; // Valid JSON
    } catch (error) {
      return false; // Invalid JSON
    }
  };
 
  // Message Process
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const getThreadMessages = async (threadId) => {
    const data = {
      'APIKEY': selectedAgent.apikey,
      'SECRETKEY': secretKey,
      'LOGINKEY': loginKey,
      'thread_id': threadId,
    };
    
    const response = await AxiosPostRequest(`${siteUrl}/api/getThread.cfm`, data);
    
    let responseThread;
    // let response = rawResponse
    //   .replace(/"([^"]+)":\s*"([^"]+)"\s*"([^"]+)"/g, '"$1":"$2","$3"') // Add missing commas
    //   .replace(/,\s*}/g, '}') // Remove trailing commas before closing braces
    //   .replace(/,\s*]/g, ']'); // Remove trailing commas before closing brackets

    if (response && Array.isArray(response.Thread) && response.Thread.length > 0) {
      responseThread = response.Thread[0]; // Get the first thread
    } else {     
      const cleanedString = cleanJsonString(response);
      if (isValidJSON(cleanedString)) {
        const responseString = JSON.parse(cleanedString); // Convert JSON string to object
        if (Array.isArray(responseString.Thread) && responseString.Thread.length > 0 ) {
          responseThread = responseString.Thread[0];
        } else {
          return [];
        }
      } else {
        return [];
      }
    }

    

    const messagesWithComments = Array.isArray(responseThread.Messages) && responseThread.Messages.length > 0 ? responseThread.Messages.map((message) => {
      const responseComments = responseThread.Comments || [];
      const responseFiles = responseThread.Files || [];

      const associatedComments = responseComments.filter(comment => comment.message_id === message.message_id);
      const associatedFiles = responseFiles.filter(file => (file.file_URL !== "") && (file.file_URL === message.fileURL));
           
      return {
        ...message,
        thread_id: threadId,
        message: message.role === 'user'? message.message : decodeData(message.message),
        attachments: associatedFiles.length > 0 ? associatedFiles[0] : null,
        comments: associatedComments.length > 0 ? associatedComments : null
      };
    }): []

    messagesWithComments.sort((a, b) => {
      return a.created_at - b.created_at; // Ascending order for older first
    });

    return messagesWithComments;
  }

  // Message File Process
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isReUploadedFile, setIsReUploadedFile] = useState(false);
  const [agentStatus, setAgentStatus] = useState('')

  const getFileType = (filename) => {
    return filename.split('.').pop();
  };

  const getToolsByFileType = (fileName) => {
    if (['.csv', '.xls', '.xlsx'].some(ext => fileName.endsWith(ext))) {
      return [{ "type": 'code_interpreter' }];
    }
    return [{ "type": 'file_search' }];
  };

  const handleUploadButtonClick = () => {    
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    setIsUploading(true);

    const newFile = event.target.files[0];

    if(newFile && newFile.size < 50 * 1024 * 1024 ) {
      if (
        newFile.type === 'text/plain' || 
        newFile.type === 'application/json' || 
        newFile.type === 'application/pdf' ||
        newFile.type === 'text/csv' || 
        newFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // For .xlsx
      ) {
        setFile(newFile);

        const uploadedFile = await handleNormalUploadToAI(newFile);

        setUploadedFile(uploadedFile);
        setIsReUploadedFile(false);

        if (newFile.type === 'text/plain' || newFile.type === 'application/json') {
          readTextOrJsonFile(newFile);
        } else if (newFile.type === 'application/pdf') {
          await readPdfFile(newFile);
        } else {
          setFileContent('    ');
        }

        event.target.value = null;
        setIsUploading(false);
      } else {
        setIsUploading(false);
        Swal.fire({
          title: `<h2 class="text-lg ${theme === 'dark' ? 'text-white' : 'text-black'}">Error</h2>`,
          html: `<p class="text-sm ${theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}">It supports only .txt, .pdf, .json, .xls, .xlsx and .csv file. Please try again.</p>`,
          background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1', // Set background color based on dark mode
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: 'bg-blue-500 rounded-xl hover:bg-blue-700 text-white text-sm px-2 mb-4', // Custom confirm button
            popup: 'p-2', // Full width on mobile, smaller on larger screens
          },
        });
      }      
    } else {
      setIsUploading(false);
      Swal.fire({
        title: `<h2 class="text-lg ${theme === 'dark' ? 'text-white' : 'text-black'}">Error</h2>`,
        html: `<p class="text-sm ${theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}">The selected file size is over 50MB. Please try again.</p>`,
        background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1', // Set background color based on dark mode
        confirmButtonText: 'Okay',
        customClass: {
          confirmButton: 'bg-blue-500 rounded-xl hover:bg-blue-700 text-white text-sm px-2 mb-4', // Custom confirm button
          popup: 'p-2', // Full width on mobile, smaller on larger screens
        },
      })
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setUploadedFile(null);
    setFileContent(null);
  };
  
  const handleUploadFile = async(messageId) => {
    if (!uploadedFile || isReUploadedFile) {
      return "";
    }
    
    const fileURL = await handleUploadFileToGoogleBucket();
  
    await handleNewFile(uploadedFile.filename, fileURL, uploadedFile.id, messageId);

    if (selectedAgent.openai_vector_store_id) {
      if( !(['.csv', '.xls', '.xlsx'].some(ext => uploadedFile.filename.endsWith(ext)))) {
        const result = await Swal.fire({
          title: `<h2 class="text-lg ${theme === 'dark' ? 'text-white' : 'text-black'}">Add this document to Memory</h2>`,
          html: `<p class="text-sm ${theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}">If you select ‘Yes’, this document will be added to the AI Agent’s memory and allow other users access to this information. If you only want to use this document in this private conversation and not shared please select ‘No’.</p>`,
          background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1', // Set background color based on dark mode
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'bg-blue-500 rounded-xl hover:bg-blue-700 text-white text-sm px-2 mb-4 mr-6', // Custom save button
            cancelButton: 'bg-gray-500 rounded-xl hover:bg-gray-300 text-white text-sm px-2 mb-4', // Custom cancel button
            popup: 'p-2', 
          }
        });

        if (result.isConfirmed) {
          await handleCreateVectoreStoreFile(uploadedFile.id);
        }
      }
    } else {
      if( !(['.csv', '.xls', '.xlsx'].some(ext => uploadedFile.filename.endsWith(ext)))) {
        await Swal.fire({
          title: `<h2 class="text-lg ${theme === 'dark' ? 'text-white' : 'text-black'}">Add this document to Memory</h2>`,
          html: `<p class="text-sm ${theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}">Access is denied for uploading documents for this agent.</p>`,
          background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1', // Set background color based on dark mode
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: 'bg-blue-500 rounded-xl hover:bg-blue-700 text-white text-sm px-2 mb-4 mr-8', // Custom save button
            popup: 'p-2', 
          }
        });
      }
    }

    return fileURL;
  };

  const reUploadFile = async(fileId2, fileName) => {
    const fileId = "file-FCllj8vGqtlBR1DDdInRfwmq";

    setFile({name: fileName})
    // setFile(newFile);
    
    // const uploadedFile = await handleNormalUploadToAI(newFile);
    setUploadedFile({filename: fileName, id:fileId});
    setFileContent('      ');
    setIsReUploadedFile(true);
  }

  const readTextOrJsonFile = (file) => {
    try {
      const reader = new FileReader();
      reader.onload = (event) => setFileContent(event.target.result);
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };

  const readPdfFile = async (file) => {
    try {
      const reader = new FileReader();
  
      reader.onload = async (event) => {
        try {
          const typedArray = new Uint8Array(event.target.result);
  
          // Set up the worker.
          pdfJS.GlobalWorkerOptions.workerSrc = 
            window.location.origin + '/pdf.worker.min.mjs';
  
          const pdf = await pdfJS.getDocument(typedArray).promise;
          let textContent = '';
  
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const text = await page.getTextContent();
            textContent += text.items.map((item) => item.str).join(' ') + '\n';
          }
  
          setFileContent(textContent);
        } catch (error) {
          console.error('Error processing PDF:', error);
        }
      };
  
      reader.onerror = (error) => {
        console.error('File reading error:', error);
      };
  
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };

  const handleNormalUploadToAI = async(attachedFile) => {
    const formData = new FormData();
    formData.append('file', attachedFile);
    formData.append('purpose', 'assistants'); // Required field: 'purpose'

    const headers = {
      'Authorization': `Bearer ${selectedAgent.openai_api_key}`,
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await AxiosPostRequestFormdata(`https://api.openai.com/v1/files`, formData, headers);     
      return response;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  }

  const handleCreateVectoreStoreFile = async(fileId) => {
    try {
      const myVectorStoreFile = await openai.beta.vectorStores.files.create(
        selectedAgent.openai_vector_store_id,
        { file_id: fileId }
      );
    } catch (error) {
      console.error('Error creating Vector Store File:', error);
    }
  }

  const handleUploadFileToGoogleBucket = async() => {
    if(!file) return;

    const now = new Date();
    const dateString = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
    const timeString = `${now.getTime()}_`;

    const storageRef = ref(storage, `uploads/${dateString}/${timeString + file.name}`);

    try {
      const snapshot = await uploadBytes(storageRef, file);
      // const consoleURL = `https://console.cloud.google.com/storage/browser/${snapshot.ref.bucket}/${snapshot.ref.fullPath}?project=${firebaseConfig.projectId}`;      
      const downloadUrl = await getDownloadURL(storageRef);
      return downloadUrl;
    } catch (error) {
      console.error('Upload failed:', error);
      return null;
    }
  }

  const handleNewFile = async(fileName, fileURL, fileId, messageId) => {
    const data = {
      'APIKEY': selectedAgent.apikey,
      'SECRETKEY': secretKey,
      'loginKey': loginKey,
      'Filename': fileName,
      'fileURL': fileURL,
      'File_id': fileId,
      'tool': getToolsByFileType(fileName),
      'file_extension': getFileType(fileName),
      'message_id': messageId
    };
    
    try {
      let response = await AxiosPostRequest(`${siteUrl}/api/newFile.cfm`, data);
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
    }
  }

  // Chat Process
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useRef(null);  
 
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleStartNewChat = () => {
    setIsSidebarOpen(false);
    setThread(null);
    setAgentStatus('')
    setIsThreadAdminMode(false);
    setMessage('');    
    setMessages([]);
    setFile(null);
    setRows(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    handleStartChat(message, false);   
  };

  const handleStartChat = async (newMessageText, isSuggestion = false) => {
    setIsLoading(true);

    if (isThreadAdminMode) {
      const now = new Date();
      const timeString = now.getTime()

      const newMessage = { 
        message: newMessageText, 
        role: 'admin',
        created_at: timeString,
        attachments: null
      };

      // Create newMessages without attachments
      const newMessages = [...messages, newMessage];
  
      // Update the messages state
      setMessages(newMessages);
      setMessage('');
      
      setRows(1);
      setFile(null);

      setIsLoading(false);

      saveMessage( thread.thread_id, '', '', timeString, newMessageText, 'admin', '', 'text');

    } else {
      const newMessage = { 
        message: newMessageText, 
        role: 'user',
        attachments: file ? {file_name: file.name} : null // Initially set attachments to null
      };
      
      // Create newMessages without attachments
      const newMessages = [...messages, newMessage];
  
      // Update the messages state
      setMessages(newMessages);
      setMessage('');
      setRows(1);
      setFile(null);
      
      // Handle file upload if it exists 
      newMessage.attachments = uploadedFile ? {...uploadedFile, file_name: uploadedFile.filename} : null;

      handleSendMessage(newMessage, isSuggestion);
    }    
  }

  const handleSendMessage = async (newMessage, isSuggestion, isSaveUserMessage = true) => {
    const { run, user_message, assistant_message, thread_id, error } = await sendMessage(newMessage, isSuggestion);

    if (!error) {
      const replyText = assistant_message.content[0].text.value;

      let tmpHandoff = false

      if (isValidJSON(replyText)){
        let tmpAgent = JSON.parse(replyText);
        if (tmpAgent.response === "handoff"){
          tmpHandoff = true
        }
      }      
      
      setMessages(prevMessages => [...prevMessages, 
        { 
          message: decodeData(replyText),
          messagetype: tmpHandoff ? 'handoff' : 'text',
          message_id: assistant_message.id,
          thread_id: thread_id,
          role: 'assistant',
        }
      ]);

      saveRun(run);
      setIsLoading(false);
      setDisplayText('')

      const fileURL = await handleUploadFile(user_message.id);
      setUploadedFile(null);

      if (isSaveUserMessage) {
        saveMessage(thread_id, run.id, user_message.id, user_message.created_at, newMessage.message, user_message.role, fileURL, 'text');
      }      

      saveMessage(thread_id, run.id, assistant_message.id, assistant_message.created_at, replyText, assistant_message.role, '', replyText.includes('{"response": "handoff",') ? 'handoff' : 'text');

      if (tmpHandoff) {         
        setIsLoading(true);
        let tmpAgent = JSON.parse(replyText);
        const tmpAgents = agents.filter(agent => agent.openai_assistant_id === tmpAgent.value);
        if(tmpAgents.length > 0) {
          setAgentStatus('handoff')
          if (tmpAgents[0] !== transferedAgent) {
            setTransferedAgent(tmpAgents[0])
          }          
        }                
      }
      
    } else {
      Swal.fire({
        title: `<h2 class="text-lg ${theme === 'dark' ? 'text-white' : 'text-black'}"></h2>`,
        html: `<p class="text-sm ${theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}">Error sending message</p>`,
        background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1', // Set background color based on dark mode
        showCancelButton: true,
        confirmButtonText: 'Try again',
        cancelButtonText: 'Cancel',
        heightAuto: false,
        customClass: {
          confirmButton: 'bg-blue-500 rounded-xl hover:bg-blue-700 text-white text-sm px-2 mb-4 mr-6', // Custom save button
          cancelButton: 'bg-gray-500 rounded-xl hover:bg-gray-300 text-white text-sm px-2 mb-4', // Custom cancel button
          popup: 'p-2', 
        },
      }).then((result) => {
        if (result.isConfirmed) {
          handleSendMessage(newMessage, isSuggestion);
        } else {
          setIsLoading(false);      
          setUploadedFile(null);
        }
      });
    }
  }

  // Helper function to construct the user message
  const constructUserMessage = (userMessageObject, fileContent, maxLength, maxFullLength) => {
    let sensitiveContent = "";
    let userMessageContent = userMessageObject.message;

    if (fileContent) {
      if (fileContent.length > maxFullLength) {
        sensitiveContent = `${fileContent.substring(0, maxLength)}... (content truncated due to size limit)`;
        userMessageContent = `
          The user uploaded a file (${userMessageObject.message}) exceeding ${maxFullLength} characters.
          Truncated content: ${sensitiveContent}.
          Full content used for analysis.
        `;
      } else {
        sensitiveContent =
          fileContent.length > maxLength
            ? `${fileContent.substring(0, maxLength)}... (content truncated)`
            : fileContent;

        userMessageContent = `
          The user uploaded a file (${userMessageObject.message}).
          Content: ${sensitiveContent}.
          Full content used for analysis.
        `;
      }
    }

    return { content: userMessageContent, sensitiveContent };
  };

  // Helper function to monitor the run status
  const monitorRun = async (threadId, runId, curAgent) => {
    let run = await openai.beta.threads.runs.retrieve(threadId, runId);
  
    while (!["completed", "cancelling", "cancelled", "failed", "expired"].includes(run.status)) {
      await new Promise((resolve) => setTimeout(resolve, 300));
      run = await openai.beta.threads.runs.retrieve(threadId, run.id);
  
      if (run.status === "requires_action") {
        // console.log("Run with status requires_action ===>", run)
        run = await handleActionRequired(run, threadId, runId, curAgent);
      }
    }
  
    return run;
  };

  const [displayText, setDisplayText] = useState('');

  // Helper function to handle actions required by the run
  const handleActionRequired = async (run, threadId, runId, curAgent) => {
    const toolCall = run.required_action.submit_tool_outputs.tool_calls[0];
    const { id: tool_call_id, function: { name: called_function, arguments: function_arguments } } = toolCall;
    let responseBody = "";

    if (curAgent.Functions?.length > 0) {
      const matchedFunction = curAgent.Functions.find((func) => func.FunctionName === called_function);

      if (matchedFunction) {
        setDisplayText(matchedFunction.DisplayText)

        try {
          const responseWebhook = await AxiosPostRequest(matchedFunction.Webhook, function_arguments);
          // console.log("Webhook response ===>", responseWebhook);

          if (typeof responseWebhook === 'string') {
            responseBody = responseWebhook
          } else {
            if (typeof responseWebhook === 'object' && responseWebhook !== null) {
              responseBody = JSON.stringify(responseWebhook);
            }       
          }

        } catch (error) {
          console.error(error.response ? error.response.data : error.message);
        }
      }
    }

    if (!responseBody) {
      console.error("No match found or no response from webhook");
      return run;
    }

    return await openai.beta.threads.runs.submitToolOutputs(threadId, runId, {
      tool_outputs: [
        {
          tool_call_id,
          output: responseBody,
        },
      ],
    });
  };

  const createAdditionalInstructions = (curAgent) => {
    // Bot instructions 
    let additional_instructions = `### THE CURRENT DATE AND TIME IS ${new Date().toLocaleString()} `;

    additional_instructions += `${curAgent.intro}`

    // let fullName = currentThread.fname + " " + currentThread.lname
    // fullName = fullName === " " ? "" : fullName

    // if (fullName) {
    //   additional_instructions += `**Name** The user's name is ${fullName}\n`;
    // }
    // if (currentThread.email) {
    //   additional_instructions += `**Email** The user's email is ${currentThread.email}\n`;
    // }
    // if (currentThread.phone) {
    //   additional_instructions += `**Phone** The user's phone is ${currentThread.phone}\n`;
    // }

    // Transfer instructions
    if (curAgent.TransferAgents?.length > 0) {
      const formattedAgents = curAgent.TransferAgents.map(agent => 
          `{"Assistant_ID": "${agent.transfer_assistant_id}", "Instructions": "${agent.transfer_instructions}", "transfer_BOTNAME": "${agent.transfer_agentname}"}`
      ).join('; ');
      
      additional_instructions += ` ### IF YOU CANNOT ANSWER THE QUESTION AND ONE OF THESE TRANSFER AGENTS CAN, CONFIRM WITH THE USER THAT THEY WOULD LIKE TO BE TRANSFERRED TO THE APPROPRIATE AGENT. ONCE THEY CONFIRM, RESPOND BACK IN THIS FORMAT WITH NO ADDITIONAL MARKDOWN, JUST THIS JSON RESPONSE: {"response": "handoff", "value": "#Assistant_ID#", "transfer_BOTNAME": "#transfer_BOTNAME#"}. HERE ARE THE TRANSFERABLE AGENTS YOU HAVE ACCESS TO: [${formattedAgents}]`;
    }

    // Function-specific instructions
    if (curAgent.Functions?.length > 0) {
      const functionInstructions = curAgent.Functions.map(func => 
          `## FOR ${func.FunctionName} FUNCTION ##\r${func.Description}`
      ).join('\r\r');
      
      additional_instructions += ` ${functionInstructions}`;
    }

    return additional_instructions;
  }

  const sendMessage = async (userMessageObject, isSuggestion) => {
    if (!openai) {
      console.error("OpenAI client is not initialized.");
      return;
    }

    try {
      // Create or reuse the thread
      const currentThread = isSuggestion ? await createThread() : thread || await createThread();

      // Handle the thread list update if no messages exist
      if (messages.length === 0) {
        setThreadList((prevThreadList) => {
          const newThread = {
            ...currentThread,
            thread_id: currentThread.id,
            message: userMessageObject.message,
          };
  
          return [
            ...prevThreadList,
            newThread,
          ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        });
      }

      // Timeout promise to handle long operations
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error("Operation timed out")), 60000);
      });

      // Core message processing logic
      const processMessage = async () => {
        const MAX_LENGTH = 10000; // Set this to your desired maximum length
        const MAX_FULL_LENGTH = 200000; // Set this to the maximum allowable length for full content

        const attachments = userMessageObject.attachments
        ? [{
            "file_id": userMessageObject.attachments.id, // Accessing the id from the object
            "tools": getToolsByFileType(userMessageObject.attachments.filename),
          }]
        : [];

        const { content: userMessageContent, sensitiveContent } = constructUserMessage(
          userMessageObject,
          fileContent,
          MAX_LENGTH,
          MAX_FULL_LENGTH
        );     
        
        const userMessage = await openai.beta.threads.messages.create(currentThread.id,
          {
            "role": "user",
            "content": userMessageContent,
            "attachments": attachments
          }
        );

        setFileContent(null);
        // console.log("transferedAgent ===>", transferedAgent)
        let additionalInstructions =  agentStatus === 'handoff'? createAdditionalInstructions(transferedAgent) : createAdditionalInstructions(selectedAgent);

        const tmpRun = agentStatus === 'handoff' 
        ?  await openai.beta.threads.runs.create(currentThread.id, {
          assistant_id: transferedAgent.openai_assistant_id,
          additional_instructions: additionalInstructions
        })
        : await openai.beta.threads.runs.create(currentThread.id, {
          assistant_id: selectedAgent.openai_assistant_id,
          additional_instructions: additionalInstructions
        });

        // console.log("Run was created with additional instructions", tmpRun.assistant_id, tmpRun)

        let run = agentStatus === 'handoff' ? await monitorRun(currentThread.id, tmpRun.id, transferedAgent) : await monitorRun(currentThread.id, tmpRun.id, selectedAgent);

        if (['cancelling', 'cancelled', 'failed', 'expired'].includes(run.status)) {
          console.error("Run ended with an unexpected status:", run.status);

          return {
            run: null,
            user_message: null,
            assistant_message: null,
            thread_id: null,
            error: run.status
          }
        } else if (run.status === 'completed') {
          const assistantMessageData = await openai.beta.threads.messages.list(run.thread_id, 
            {
              limit: 1,
              order: "desc" // Assuming 'desc' orders messages from latest to oldest
            }
          );        
          
          if (assistantMessageData) {
            const assistantMessage = assistantMessageData.data[0];

            return {
              run: run,
              user_message: userMessage,
              assistant_message: assistantMessage,
              thread_id: currentThread.id,
              error: null
            };       
          }
        }
      };

      return await Promise.race([processMessage(), timeoutPromise]);
    } catch (error) {
      console.error("Error sending message:", error);
      return {
        run: null,
        user_message: null,
        assistant_message: null,
        thread_id: null,
        error: error
      }
    }
  };  

  const saveMessage = async (thread_id, run_id = '', message_id = '', message_created_at, messageContent, role, fileURL = '', messagetype = 'text') => {
    const data = {
      'APIKEY': selectedAgent.apikey,
      'SECRETKEY': secretKey,
      'LOGINKEY': loginKey,
      'thread_id': thread_id,
      'assistant_id': run_id === "" ? run_id : selectedAgent.openai_assistant_id,
      'message_id': message_id,
      'created_at': message_created_at,
      'run_id': run_id,
      'message': messageContent,
      'role': role,
      'fileURL': fileURL,
      'Userid': loginUserId,
      'messagetype': messagetype
    };

    try {
      await AxiosPostRequest(`${siteUrl}/api/saveMessage.cfm`, data);
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
    }
  }

  // Feedback
  const showComment = async(threadId, messageId, isThumbsUp, comment, training) => {
    const formattedComment = comment? comment.replace(/\.\s+/g, '.\n') : '';
    const formattedTraining = training ? training.replace(/\.\s+/g, '.\n') : '';

    Swal.fire({
      title: `<h2 class="text-lg ${theme === 'dark' ? 'text-white' : 'text-black'}" style="text-align: left;">AI Feedback</h2>`,
      html: `
        <textarea id="textarea1" class="swal2-input" placeholder="Give the AI Feedback here..."></textarea>
        <textarea id="textarea2" class="swal2-input mt-2" placeholder="Please copy and paste answer given by the AI Agent with edits here (if any)"></textarea>
      `,
      background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1', // Set background color based on dark mode
      showCancelButton: true,
      confirmButtonText: '&nbsp;Edit&nbsp;',
      cancelButtonText: 'Cancel',
      width: '640px',
      customClass: {
        confirmButton: 'bg-blue-500 rounded-xl hover:bg-blue-700 text-white text-sm px-2 mb-4 mr-6', 
        cancelButton: 'bg-gray-500 rounded-xl hover:bg-gray-300 text-white text-sm px-2 mb-4', 
        popup: 'p-2',
      },
      didOpen: () => {
        const textarea1 = document.getElementById('textarea1');
        const textarea2 = document.getElementById('textarea2');

        textarea1.value = formattedComment;  
        textarea2.value = formattedTraining;  

        textarea1.setAttribute('readonly', true);
        textarea2.setAttribute('readonly', true);    
    
        [textarea1, textarea2].forEach((textarea) => {
          if (textarea) {
            textarea.classList.add('text-sm', 'border', 'p-2', 'rounded');
            if (theme === 'dark') {
              textarea.classList.add('text-white', 'bg-[#1A1B1C]', 'border-[#5A5B5C]');
            } else {
              textarea.classList.add('text-black', 'bg-gray-200', 'border-gray-300', 'placeholder-gray-500');
            }
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.width = '100%';  // Full width
            textarea.style.resize = 'none';  // Prevent resizing
            textarea.style.padding = '0.5rem';  // Padding of 1rem
          }
        });
        textarea1.style.height = '60px';
        textarea2.style.height = '150px';
      }
    }).then((result) => {
      if (result.isConfirmed) {
        writeComment(threadId, messageId, isThumbsUp, true, formattedComment, formattedTraining);
      }
    });
  }

  const writeComment = async(threadId, messageId, isThumbsUp, isEdit = false, formattedComment = '', formattedTraining = '') => {
  
    if (isEdit) {
      Swal.fire({
        title: `<h2 class="text-lg ${theme === 'dark' ? 'text-white' : 'text-black'}" style="text-align: left;">${!isEdit ? 'Give the AI Feedback' : 'Edit the AI Feedback'}</h2>`,
        html: `
          <div class="icon-container flex items-center space-x-4 mb-4 ml-2">
            <i id="infoIcon" class="${isThumbsUp ? 'fas fa-thumbs-up' : 'far fa-thumbs-up'} ${theme === 'dark' ? 'text-white' : 'text-black'} cursor-pointer"></i>
            <i id="questionIcon" class="${isThumbsUp ? 'far fa-thumbs-down' : 'fas fa-thumbs-down'} ${theme === 'dark' ? 'text-white' : 'text-black'} cursor-pointer"></i>
          </div>
          <textarea id="textarea1" class="swal2-input" placeholder="Give the AI Feedback here..."></textarea>
          <textarea id="textarea2" class="swal2-input mt-2" placeholder="Please copy and paste answer given by the AI Agent with edits here (if any)"></textarea>
        `,
        background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1',
        showCancelButton: true,
        confirmButtonText: '&nbsp;Save&nbsp;',
        cancelButtonText: 'Cancel',
        width: '640px',
        customClass: {
          confirmButton: 'bg-blue-500 rounded-xl hover:bg-blue-700 text-white text-sm px-2 mb-4 mr-6',
          cancelButton: 'bg-gray-500 rounded-xl hover:bg-gray-300 text-white text-sm px-2 mb-4',
          popup: 'p-2',
        },
        didOpen: () => {
          const textarea1 = document.getElementById('textarea1');
          const textarea2 = document.getElementById('textarea2');
      
          textarea1.value = formattedComment;
          textarea2.value = formattedTraining;
      
          // Apply styles to the textareas
          [textarea1, textarea2].forEach((textarea) => {
            if (textarea) {
              textarea.classList.add('text-sm', 'border', 'p-2', 'rounded');
              if (theme === 'dark') {
                textarea.classList.add('text-white', 'bg-[#1A1B1C]', 'border-[#5A5B5C]');
              } else {
                textarea.classList.add('text-black', 'bg-gray-200', 'border-gray-300', 'placeholder-gray-500');
              }
              textarea.style.outline = 'none';
              textarea.style.boxShadow = 'none';
              textarea.style.width = '100%';
              textarea.style.resize = 'none';
              textarea.style.padding = '0.5rem';
            }
          });
          textarea1.style.height = '60px';
          textarea2.style.height = '150px';
      
          // Add click events to icons to toggle icon classes (thumbs up and thumbs down)
          const infoIcon = document.getElementById('infoIcon');
          const questionIcon = document.getElementById('questionIcon');
      
          // Toggle between thumbs up and thumbs down on icon click
          infoIcon.onclick = () => {
            isThumbsUp = true

            infoIcon.classList.toggle('fas');
            infoIcon.classList.toggle('far');
            questionIcon.classList.toggle('fas');
            questionIcon.classList.toggle('far');
          };
      
          questionIcon.onclick = () => {
            isThumbsUp = false

            infoIcon.classList.toggle('fas');
            infoIcon.classList.toggle('far');
            questionIcon.classList.toggle('fas');
            questionIcon.classList.toggle('far');
          };
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const comment = document.getElementById('textarea1').value;
          const training = document.getElementById('textarea2').value;
          handleSaveComment(threadId, messageId, isThumbsUp, comment, training, true);
        }
      });   

    } else {
      Swal.fire({
        title: `<h2 class="text-lg ${theme === 'dark' ? 'text-white' : 'text-black'}" style="text-align: left;">${!isEdit ? 'Give the AI Feedback' : 'Edit the AI Feedback'}</h2>`,
        html: `
          <textarea id="textarea1" class="swal2-input" placeholder="Give the AI Feedback here..."></textarea>
          <textarea id="textarea2" class="swal2-input mt-2" placeholder="Please copy and paste answer given by the AI Agent with edits here (if any)"></textarea>
        `,
        background: theme === 'dark' ? '#1A1B1CF1' : '#EEEEEEF1', // Set background color based on dark mode
        showCancelButton: true,
        confirmButtonText: '&nbsp;Save&nbsp;',
        cancelButtonText: 'Cancel',
        width: '640px',
        customClass: {
          confirmButton: 'bg-blue-500 rounded-xl hover:bg-blue-700 text-white text-sm px-2 mb-4 mr-6', 
          cancelButton: 'bg-gray-500 rounded-xl hover:bg-gray-300 text-white text-sm px-2 mb-4', 
          popup: 'p-2',
        },
        didOpen: () => {
          const textarea1 = document.getElementById('textarea1');
          const textarea2 = document.getElementById('textarea2');
  
          textarea1.value = formattedComment;  
          textarea2.value = formattedTraining;  
      
          [textarea1, textarea2].forEach((textarea) => {
            if (textarea) {
              textarea.classList.add('text-sm', 'border', 'p-2', 'rounded');
              if (theme === 'dark') {
                textarea.classList.add('text-white', 'bg-[#1A1B1C]', 'border-[#5A5B5C]');
              } else {
                textarea.classList.add('text-black', 'bg-gray-200', 'border-gray-300', 'placeholder-gray-500');
              }
              textarea.style.outline = 'none';
              textarea.style.boxShadow = 'none';
              textarea.style.width = '100%';  // Full width
              textarea.style.resize = 'none';  // Prevent resizing
              textarea.style.padding = '0.5rem';  // Padding of 1rem
            }
          });
          textarea1.style.height = '60px';
          textarea2.style.height = '150px';
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const comment = document.getElementById('textarea1').value;
          const training = document.getElementById('textarea2').value;
          handleSaveComment(threadId, messageId, isThumbsUp, comment, training);
        }
      });
    }    
  }

  const handleSaveComment = async(commentThreadId, commentMessageId, commentThumbsUp, comment, training, isEdit = false) => {
    const data = {
      'APIKEY': selectedAgent.apikey,
      'SECRETKEY': secretKey,
      'loginKey': loginKey,
      'thread_id': commentThreadId,
      'message_id': commentMessageId,
      'comment': comment,
      'Training': training,
      'isThumbsUp': commentThumbsUp?1:0,
      'isThumbsDown': commentThumbsUp?0:1,
      'created_at': Date.now()
    };

    try {
      const response = await AxiosPostRequest(`${siteUrl}/api/newComment.cfm`, data);

      setMessages((prevMessages) =>
        prevMessages.map((msg) => {
          if (msg.message_id === commentMessageId && msg.thread_id === commentThreadId) {
            const newComment = {
              'comment': comment,
              'created_at': "", // Add the correct created_at if necessary
              'isThumbsDown': commentThumbsUp ? "0" : "1",
              'isThumbsUp': commentThumbsUp ? "1" : "0",
              'loginKey': loginKey,
              'message_id': commentMessageId,
              'training': training
            };
      
            let updatedComments = [];
      
            if (isEdit) {
              updatedComments = msg.comments ? [...msg.comments] : [];              
              if (updatedComments.length > 0) {
                updatedComments.shift();
              }              
              updatedComments.unshift(newComment);
              // updatedComments = msg.comments ? [...msg.comments, newComment] : [newComment];
            } else {
              updatedComments = msg.comments ? [...msg.comments, newComment] : [newComment];
            }
      
            return { ...msg, comments: updatedComments };
          }
          return msg;
        })
      );

    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isShowMessageComments, setIsShowMessageComments] = useState(false);

  const renderShowMessageComments = () => {
    if (!selectedMessage) return null

    const thumbsUpCount = selectedMessage.comments.filter(comment => comment.isThumbsUp === "1").length;
    const thumbsDownCount = selectedMessage.comments.filter(comment => comment.isThumbsUp !== "1").length;
    const messageText = selectedMessage.message.length > 44 ? selectedMessage.message.substring(0, 44) + "..." : selectedMessage.message

    return (
      <div
        className={`fixed top-0 right-0 h-full w-[320px] transform transition-transform duration-500 ease-in-out
        border-l-2  bg-gray-200 dark:border-threeoptions-background text-sm
        ${theme === 'dark' ? 'bg-sidebar-background text-white' : 'bg-white text-black'}
        ${isShowMessageComments ? 'translate-x-0' : 'translate-x-full'}
        `}
      >
        <button
          className="ml-5 mt-6 hover:text-gray-500"
          onClick={() => setIsShowMessageComments(false)}>
          <i className="far fa-arrow-alt-circle-right" style={{ fontSize: '22px' }}></i>
        </button>
  
        <div className="p-2" >
          <div className="flex flex-col m-2 mt-5">
            <span className="text-md font-medium messageText text-custom-text-gray">Selected Message</span>
            <span className="text-sm font-medium text-black dark:text-white mt-2">{messageText}</span>
          </div>

          <div className="flex justify-center items-center space-x-8 p-2 mt-4">
            <div className="flex items-center gap-2">
              <i className="fas fa-thumbs-up text-green-500" style={{ fontSize: '16px' }}></i>
              <span className="text-sm font-medium">{thumbsUpCount}</span>
            </div>
            <div className="flex items-center gap-2">
              <i className="fas fa-thumbs-down text-red-500" style={{ fontSize: '16px' }}></i>
              <span className="text-sm font-medium">{thumbsDownCount}</span>
            </div>
          </div>

          <div className="border-t border-custom-hover-gray5 my-3 mx-6"></div>

          <div className={`flex flex-col p-2 overflow-y-auto md:mt-4 space-y-2`}>
            {selectedMessage.comments.map((comment, index) => (
              <div
                key={index}
                className={`w-full p-4 rounded-xl flex flex-row space-x-4 ${theme === 'dark' ? 'bg-custom-hover-gray3' : 'bg-gray-200'}`}
              >
                <div className="flex flex-1 flex-col">
                  <span className="text-sm text-black dark:text-white">{comment.created_at}</span>
                  <span className="text-sm text-black dark:text-white">{comment.comment === '' ? 'No Comment': comment.comment}</span>
                  <span className="text-sm text-black dark:text-white">{comment.training}</span>
                </div>

                <div className="flex flex-col justify-start items-center space-y-2">
                  {/* Thumb Icon */}
                  <div
                    className={`p-2 rounded-md cursor-pointer flex items-center gap-3 ${theme === 'dark' ? 'hover:bg-threeoptions-hover' : 'hover:bg-gray-200'}`}
                  >
                    { comment.isThumbsUp === "1"
                      ? <i className="fas fa-thumbs-up text-green-500" style={{fontSize: '12px'}}></i>
                      : <i className="fas fa-thumbs-down text-red-500" style={{fontSize: '12px'}}></i>
                    }
                  </div>                  

                  {/* Delete and Edit Icons */}
                  <div className="flex justify-start items-center space-x-2">
                    <div
                      className={`p-2 rounded-md cursor-pointer flex items-center gap-3 ${theme === 'dark' ? 'hover:bg-threeoptions-hover' : 'hover:bg-gray-200'}`}
                    >
                      <i className="fas fa-trash-alt" style={{fontSize: '12px'}}></i>
                    </div>

                    <div
                      className={`p-2 rounded-md cursor-pointer flex items-center gap-3 ${theme === 'dark' ? 'hover:bg-threeoptions-hover' : 'hover:bg-gray-200'}`}
                    >
                      <i className="fas fa-pencil-alt" style={{fontSize: '12px'}}></i>
                    </div>
                  </div>
                </div>
              </div>
              ))}
          </div>
        </div>
      </div>
    );
  }

  // Copy to Clipboard  
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  const copyToClipboard = async (copyText) => {
    try {
      const textArea = document.createElement("textarea");
      textArea.value = copyText;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
  
      setToastMessage("Copied to clipboard!");
      setShowToast(true);
    } catch (err) {
      setToastMessage("Failed to copy!");
      setShowToast(true);
    }
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  // Message Input Box
  const [rows, setRows] = useState(1);

  const handleKeyDown = (event) => {
    if(isMobile) {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default form submission
        setMessage((prev) => prev + '\n'); // Append new line character
  
        const lineCount = (message.match(/\n/g) || []).length + 2; // Count new lines and add 1
        if (lineCount < 8) {
          setRows(lineCount); // Set the rows based on line count
        }
      }
    } else {
      if (event.key === 'Enter' &&  event.shiftKey) {
        event.preventDefault(); // Prevent the default form submission
        setMessage((prev) => prev + '\n'); // Append new line character
  
        const lineCount = (message.match(/\n/g) || []).length + 2; // Count new lines and add 1
        if (lineCount < 8) {
          setRows(lineCount); // Set the rows based on line count
        }
      } else if (event.key === 'Enter'){
        handleSubmit(event)
      }
    }    
  };

  const handleChangeHeight = (e) => {
    const { value } = e.target;
    setMessage(value); // Update the message state

    const lineCount = (value.match(/\n/g) || []).length + 1; // Count new lines and add 1

    if (lineCount < 8) {
      setRows(lineCount); // Set the rows based on line count
    } else {
      setRows(8);
    }
  };

  return (
    <>
      {/* Show loader */}
      {globalLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-100 bg-black opacity-75 w-full" style={{ height: realHeight }}>
          <BallTriangle height="72" width="72" color={theme === 'dark' ? 'white': 'black'} />
        </div>
      )}

      <div className="flex" style={{ height: realHeight }}>    
        {/* Left Sidebar */}
        <div
          className={`fixed inset-y-0 z-50 w-3/4 md:w-1/3 max-w-[300px] ${theme === 'dark' ? 'bg-sidebar-background text-white' : 'bg-white text-black'} p-3 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:relative md:translate-x-0 flex flex-col`}
        >
          {globalLoading && (
            <div className="fixed inset-0 flex items-center justify-center z-100 bg-black opacity-75 w-full" style={{ height: realHeight }}>
            </div>
          )}

          {/* App Icon, New Chat */}
          <div className={`flex items-center justify-between p-2 rounded-lg cursor-pointer ${theme === 'dark' ? 'hover:bg-custom-hover-gray' : 'hover:bg-gray-300' }`} >
            <i 
              className="fas fas fa-bars cursor-pointer md:hidden text-md" style={{ width: '18px', height: '18px' }}
              onClick={() => {
                setIsSidebarOpen(false); // Close the sidebar
                toggleHistoryDropdown(null); // Toggle the dropdown visibility
              }}>
            </i>
            {selectedAgent && 
              <div className="flex items-center">
                <img 
                  src={selectedAgent.icon} 
                  alt={selectedAgent.alias} 
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite looping
                    e.target.src = defaultAgentIcon; // Replace with your default image URL
                  }}
                  className="w-7 h-7 rounded-full mr-2" />
                <p className="text-sm font-medium">{selectedAgent.alias}</p>
              </div>
            }
            {selectedAgent ? 
              <i className="far fa-comment-alt text-md cursor-pointer" style={{ width: '18px', height: '18px' }}
                onClick={() => {
                  handleStartNewChat(); 
                  toggleHistoryDropdown(null); // Toggle the dropdown visibility
                }}>
              </i>
              : <img 
              alt="defaultIcon"
              src={defaultAgentIcon} 
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite looping
                e.target.src = defaultAgentIcon; // Replace with your default image URL
              }}
              className="w-7 h-7 rounded-sm mr-2" />
            }
          </div>

          {selectedAgent && 
            <input
              type="text"
              placeholder="Search conversation..."
              value={searchThreadKey}
              onChange={(e) => setSearchThreadKey(e.target.value)}
              className={`flex rounded-lg  px-3 py-3 border-1 my-2 mr-3 text-sm focus:outline-none ${theme === 'dark' ? 'placeholder-agent-version-text' : 'placeholder-gray-700'} ${theme === 'dark' ? 'bg-threeoptions-background' : 'bg-gray-200'}`}
            />
          }

          {/* Thread History */}
  
          <div className={`flex-1 overflow-y-auto text-sm `}>

            { selectedAgent ? 
              threadList && threadList.length > 0 ? (
                <>
                  {categorizedThreads.today.length > 0 && renderSideThreads(categorizedThreads.today, 'Today')}
                  {categorizedThreads.yesterday.length > 0 && renderSideThreads(categorizedThreads.yesterday, 'Yesterday')}
                  {categorizedThreads.previous7Days.length > 0 && renderSideThreads(categorizedThreads.previous7Days, 'Previous 7 days')}
                  {categorizedThreads.past30Days.length > 0 && renderSideThreads(categorizedThreads.past30Days, 'Past 30 days')}
                  {categorizedThreads.older.length > 0 && renderSideThreads(categorizedThreads.older, 'Older')}
                </>
              ) : (
                <div className="p-2 text-xs font-medium text-custom-text-gray">No conversations</div> // Optional message for empty state
              )
              : null
            }          
          </div>
          
          {/* User Info at the bottom */}
          <div className="flex-shrink-0">
            <div 
              onClick={selectRaiaHelpAgent} 
              className={`flex items-center gap-3 py-[10px] pl-3 w-auto rounded-xl cursor-pointer ${theme === 'dark' ? 'hover:bg-custom-hover-gray3' : 'hover:bg-gray-300' }`}
            >
              <div className="border-2 rounded-full w-7 h-7 border-custom-bother-gray flex justify-center items-center">
                <i className="fas fa-star icon-sm shrink-0"></i>
              </div>
              <div>
                <h1 className="text-sm font-normal">ask raia</h1>
                <p className="text-xs font-normal text-custom-text-gray">
                  Do you need help?
                </p>
              </div>
            </div>

            <div 
              className={`flex items-center gap-3 py-[10px] pl-2 w-auto rounded-xl cursor-pointer relative ${theme === 'dark' ? 'hover:bg-custom-hover-gray3' : 'hover:bg-gray-300' }`}
              onClick={() => setIsShowUserMenu(!isShowUserMenu)}
              onMouseLeave={() => setIsShowUserMenu(false)}  // Clear hovered thread
            >
              <div className="flex items-center justify-center w-8 h-8 bg-custom-red rounded-full">
                <span className="text-sm">{loginUsername.charAt(0).toUpperCase()}</span>
              </div>
              <div>
                <h1 className="text-sm font-normal">{loginUsername}</h1>
              </div>

              {isShowUserMenu && 
              <div 
                className={`absolute right-0 bottom-0 w-2/3 rounded-lg shadow-lg z-10 ${theme === 'dark' ? 'bg-main-background' : 'bg-gray-200' }`}
                onClick={(e) => e.stopPropagation()} 
              >
                <div className="p-2 cursor-pointer" >
                  <div className={`flex items-center justify-start rounded-lg py-3 pl-3 ${theme === 'dark' ? 'hover:bg-custom-hover-gray3' : 'hover:bg-gray-300' }`}>
                    <span className="text-sm">{loginEmail}</span>
                  </div>                
                  <div className="border-t border-custom-hover-gray5 my-1"></div>

                  <div className={`flex items-center justify-start rounded-lg py-3 pl-3 ${theme === 'dark' ? 'hover:bg-custom-hover-gray3' : 'hover:bg-gray-300' }`}
                  onClick={() => 
                  {
                    setIsSidebarOpen(false)
                    setSearchMainAgentKey('')
                    setSelectedAgent(null)
                    setAgentStatus('')
                    setThreadList([])
                    setOriginThreadList([])
                    setCategorizedThreads([])
                    setIsThreadAdminMode(false)
                    setThread(null)
                    setIsShowMessageComments(false)
                    localStorage.removeItem('selectedAgent');
                    localStorage.removeItem('selectedThread');
                    setIsShowUserMenu(false)
                  }}
                  >
                    <span className="text-sm">Home</span>
                  </div>

                  <div className={`flex items-center justify-start rounded-lg py-3 pl-3 ${theme === 'dark' ? 'hover:bg-custom-hover-gray3' : 'hover:bg-gray-300' }`}
                  onClick={() => 
                  {
                    setIsShowSettingModal(true);
                    showSettingModal()
                  }}
                  >
                    <span className="text-sm">Settings</span>
                    {/* <Link to={"https://raia1.com/app/settings.cfm"} className="text-sm">Settings</Link> */}
                  </div>                

                  {selectedAgent && selectedAgent.isAdmin === 1 &&
                  <>
                    <div className={`flex items-center justify-start rounded-lg py-3 pl-3 space-x-4 ${theme === 'dark' ? 'hover:bg-custom-hover-gray3' : 'hover:bg-gray-300' }`}>
                      <span className="text-sm">Admin Mode</span>

                      <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" className="sr-only peer" 
                          checked={adminMode}
                          onChange={(e) => {
                            e.stopPropagation(); 
                            setIsShowUserMenu(false);
                            setAdminMode(!adminMode);
                          }}
                        />
                        <div className="w-11 h-6 bg-gray-400 rounded-full peer-checked:bg-green-500 peer-focus:ring-2 peer-focus:ring-green-300 transition-colors"></div>
                        <div className="absolute w-5 h-5 bg-white rounded-full left-0.5 top-0.5 peer-checked:translate-x-5 transition-transform"></div>
                      </label>                    
                    </div>

                    <div className="border-t border-custom-hover-gray5 my-1"></div>
                  </>
                  }

                  <div 
                    className={`flex items-center justify-start rounded-lg py-3 pl-3 ${theme === 'dark' ? 'hover:bg-custom-hover-gray3' : 'hover:bg-gray-300' }`}
                    onClick={() => handleSignout()}
                  >
                    <i className="fas fa-sign-out-alt"></i>
                    <span className="text-sm">&nbsp;Sign out</span>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>

        {/* Main Content bg-main-background */}
        {selectedAgent ?
          <div className={`flex-1 flex flex-col md:px-3 ${theme === 'dark' ? 'bg-main-background text-white' : 'bg-gray-100 text-black'} max-w-full overflow-hidden`}>
          {/* Top Header for Mobile */}
            <div 
              className="flex items-center justify-between border-b-2 border-sidebar-background md:border-0 p-3"
              onMouseLeave={() => setIsDropdownOpen(false)}  // Clear hovered thread
            >
              <i className="fas fa-bars cursor-pointer md:hidden" onClick={() => setIsSidebarOpen(true)} ></i>

              <div className="relative flex-1 flex justify-center md:justify-start" 
              >
                {selectedAgent && (
                <div 
                  className={`flex items-center p-3 rounded-lg cursor-pointer ${theme === 'dark' ? 'hover:bg-custom-hover-gray4 ' : 'hover:bg-gray-200'}`}
                  onClick={() => {
                    if (!globalLoading) {
                      toggleDropdown();
                    }
                  }}
                >
                  <span className="text-lg font-semibold">{selectedAgent.name}</span>
                  <span className="text-engine-version-text pl-2">
                    <i className="fas fa-chevron-down text-token-text-tertiary pl-2 text-xs"></i>
                  </span>
                </div>
                )}

                {isDropdownOpen && (
                <div className={`absolute z-50 mt-14 2xl:w-[400px] md:w-[45vw] lg:w-[45vw] xl:w-[25vw] rounded-lg max-h-60 overflow-y-auto ${theme === 'dark' ? 'bg-threeoptions-background' : 'bg-gray-200'}`}>
                  <input
                    type="text"
                    placeholder="Search agents..."
                    value={searchAgentKey}
                    onChange={(e) => setSearchAgentKey(e.target.value)}
                    className={`w-full px-5 py-3 border-0 border-b border-main-background mb-2 bg-transparent focus:outline-none ${theme === 'dark' ? 'placeholder-agent-version-text' : 'placeholder-gray-700'}`}
                  />
                  {filteredAgents.length > 0 ? (
                    filteredAgents.map((agent) => (
                      <div
                        key={agent.apikey}
                        className={`p-3 m-2 rounded-lg cursor-pointer flex justify-between items-center ${theme === 'dark' ? 'hover:bg-custom-hover-gray5 ' : 'hover:bg-gray-300'}`}
                        onClick={() => handleChangeAgent(agent)}
                      >
                        <div className="text-sm font-medium">
                          <p>{agent.name}</p>
                          <p className={`${theme === 'dark' ? 'text-agent-version-text' : 'text-gray-500'}`}>{agent.description}</p>
                        </div>
                        {selectedAgent.apikey === agent.apikey && <i className={`fas fa-check-circle flex-shrink-0 ${theme === 'dark' ? 'text-white' : 'text-gray-500'}`}></i>}
                      </div>
                    ))
                  ) : (
                    <div className="p-3 text-center">No agents found</div>
                  )}
                </div>
                )}
              </div>

              { adminMode && renderThreadInfo(thread) }
              
              {/* <i className="far fa-comment-alt cursor-pointer" onClick={handleStartNewChat} ></i> */}

              {/* <button
                onClick={toggleTheme}
                className={`flex items-center justify-center w-6 h-6 ml-2 rounded-full transition-colors duration-300 ${theme === 'dark' ? 'bg-gray-700 text-gray-200' : 'bg-gray-200 text-gray-700'}`}
              >
                <i className={`fas ${theme === 'dark' ? 'fa-sun' : 'fa-moon'} text-sm`} />
              </button> */}

              {isShowThreadInfo && renderShowThreadInfo()}

              {isShowMessageComments && renderShowMessageComments()}
              
            </div>

            {/* Initial view with app icon*/}
            {messages.length === 0 ? (
              <>
              {selectedAgent && (
              <div className="flex flex-col items-center justify-center flex-1">
                <img 
                  src={selectedAgent.icon} 
                  alt={selectedAgent.alias} 
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite looping
                    e.target.src = defaultAgentIcon; // Replace with your default image URL
                  }}
                  className="w-12 h-12 mb-2 rounded-full" />
                <h1 className="text-center md:mb-8 text-2xl text-medium p-3 md:px-16">{selectedAgent.intro}</h1>
              </div>
              )}
              </>
            ) : (
              <>
                {/* Chat Messages Area */}
                <div className= {`flex-1 p-2 overflow-y-auto md:mt-4`} ref={scrollRef}>
                  {messages.map((msg, index) => (                    
                    <div key={index} className={`mb-3 pr-1 flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                      { msg.messagetype === "handoff"
                        ? (<div className={`my-4 flex flex-col items-center full-width`}>
                            <p className={`${theme === 'dark' ? 'text-agent-version-text' : 'text-gray-500'} text-xs font-normal`}>…handing off to appropriate agent…</p>
                          </div>)
                        : (<div className={`flex flex-col ${msg.role === 'user' ? 'items-end max-w-[90%] md:max-w-[70%]' : 'items-start full-width'} `}>
                          
                        {msg.attachments && (
                          <div className="flex items-center p-2">
                            <FileIcon fileName={msg.attachments.file_name} />
                            <span
                              className="text-sm truncate ml-1 w-half cursor-pointer hover:text-custom-hover-gray2"
                              onClick={() => reUploadFile(msg.fileURL, msg.attachments.file_name)}
                            >
                              {msg.attachments.file_name}
                            </span>
                          </div>
                        )}

                        <div className={`p-2 rounded-xl prose ${msg.role !== 'assistant' ? theme === 'dark' ? 'bg-custom-hover-gray3 px-5' : 'bg-gray-200 px-5' : ''}`}>
                          <div className={`${theme === 'dark' ? 'text-white' : 'text-black'}`}>
                            <ReactMarkdown
                              rehypePlugins={[rehypeRaw]} // Optional: to render raw HTML
                              className="markdown"
                              components={{
                                  a: ({ node, ...props }) => (
                                  <a {...props} target="_blank" rel="noopener noreferrer">
                                    {props.children}
                                  </a>
                                ),
                                code({ node, inline, className, children }) {
                                  return (
                                    <div className="code-container">
                                      <code className={className}>{children}</code>
                                    </div>
                                  );
                                },
                              }}
                            >
                              {msg.message}
                            </ReactMarkdown>
                          </div>
                        </div>

                        {/* Icons Row */}
                        {msg.role !== 'user' && (
                          <div className= {`flex justify-center items-center space-x-2 ${theme === 'dark'} ? 'text-comment-text' : 'text-gray-700'`}>

                            {/* Thumb Icons */}
                            {msg.comments ? (
                              msg.comments[0].isThumbsUp === "1" ? (
                                // Show thumbs up icon if user liked the comment
                                <button className="p-2 rounded-full bg-transparent" onClick={() => showComment(msg.thread_id, msg.message_id, true, msg.comments[0].comment, msg.comments[0].training)}>
                                  <i className="fas fa-thumbs-up"></i>
                                </button>
                              ) : (
                                <button className="p-2 rounded-full bg-transparent" onClick={() => showComment(msg.thread_id, msg.message_id, false, msg.comments[0].comment, msg.comments[0].training)}>
                                  <i className="fas fa-thumbs-down"></i>
                                </button>
                              )
                            ) : (
                              <>
                              <button className="p-2 rounded-full bg-transparent" onClick={() => writeComment(msg.thread_id, msg.message_id, true)}>
                                <i className="far fa-thumbs-up"></i>
                              </button>
                              <button className="p-2 rounded-full bg-transparent" onClick={() => writeComment(msg.thread_id, msg.message_id, false)}>
                                <i className="far fa-thumbs-down"></i>
                              </button>
                              </>
                            )}
                            
                            {/* Copy Icon */}
                            <button className="p-2 rounded-full bg-transparent" onClick={() => copyToClipboard(msg.message)}>
                              <i className="far fa-copy"></i>
                            </button>
                            {showToast && <Toast message={toastMessage} onClose={handleCloseToast} />}

                            {/* Sparkle Icon */}
                            {/* {msg.comments ? 
                              ( msg.role === "assistant" 
                                ? (<button className="p-2 rounded-full bg-transparent">
                                    <IconContext.Provider value={{ color: theme === 'dark' ? "white" : "black", size: '20px' ,className: "global-class-name" }}>
                                      <div>
                                        <LuSparkle />
                                      </div>
                                    </IconContext.Provider>
                                  </button>) 
                                : (<button className="p-2 rounded-full bg-transparent">
                                    <IconContext.Provider value={{ color: "grey", size: '20px' ,className: "global-class-name" }}>
                                      <div>
                                        <LuSparkle />
                                      </div>
                                    </IconContext.Provider>
                                  </button>)
                              )
                              : null 
                            } */}

                            {/* CommentList Icon */}
                            {msg.comments ? 
                              ( adminMode
                                ? <button className="p-2 rounded-full bg-transparent" 
                                  onClick={() => {                                    
                                    setSelectedMessage(msg)
                                    setIsShowMessageComments(true)
                                  }}>
                                  <i className="fas fa-list-ul"></i>
                                </button> 
                                : null
                              )
                              : null 
                            }
                          </div>
                        )}
                          </div>)                         
                      }                                                             
                    </div>
                  ))}

                  {/* Show loader */}
                  {isLoading && <div className="flex flex-row items-center pl-2" >
                    { displayText === ''
                      ? <ThreeDots height="32" width="32" color={theme === 'dark' ? 'white' : 'black'}/>
                      : <Loader loadingText={displayText} theme={theme}/>
                    }     
                    </div>} 
                </div>
              </>
            )}

            <div className="flex-shrink-0 flex flex-col px-1 md:px-3 py-3 md:px-8">
              {/* Suggestions Grid - Only show if no messages exist */}
              {messages.length === 0 && (
                <div className="grid lg:grid-cols-2 gap-3 mb-4">
                  {suggestionsToDisplay.map((suggestion, index) => (
                  <div
                    key={suggestion}
                    className={`h-14 flex justify-between items-center p-3 border rounded-xl text-left cursor-pointer transition-all duration-200 group ${theme === 'dark' ? 'hover:bg-custom-hover-gray4 border-suggestion-border ' : 'hover:bg-gray-300 border-gray-200'}`}
                    onClick={() => {handleStartChat(suggestion, true) }}
                  >
                    <div className="flex flex-col">
                      <p className="text-sm font-medium">{suggestion}</p> 
                      <p className="text-sm font-medium text-suggestion-decription-text">{suggestionDescsToDisplay[index]}</p>
                    </div>

                    {/* Button only visible on hover */}
                    <button className={`ml-2 p-3 rounded-md flex items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200 ${theme === 'dark' ? 'bg-main-background ' : 'bg-gray-200' }`}>
                      <i className="fas fa-arrow-up icon-sm text-token-text-primary"></i>
                    </button>
                  </div>
                  ))}
                </div>
              )}

              <form id="input-box" onSubmit={handleSubmit} >
                <div className={`flex items-center py-3 border rounded-3xl ${theme === 'dark' ? 'border-suggestion-border ' : 'border-gray-200' }`}>
                  {/* Attach Files Button */}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept=".json, .txt, .pdf, .csv, .xls, .xlsx" // Accept only JSON, TXT, and PDF files
                  />
                  <button 
                    type="button" 
                    disabled={isLoading} 
                    onClick={handleUploadButtonClick}
                    className="p-1 bg-transparent text-button-background rounded-lg flex items-center mx-1 cursor-pointer"
                  >
                    <svg 
                      width="24" 
                      height="24" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        fillRule="evenodd" 
                        clipRule="evenodd" 
                        d="M9 7C9 4.23858 11.2386 2 14 2C16.7614 2 19 4.23858 19 7V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V7C17 5.34315 15.6569 4 14 4C12.3431 4 11 5.34315 11 7V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V7Z"           
                        fill={`${theme === 'dark' ? 'white' : 'black' }`}
                      />
                    </svg>
                  </button>

                  <div className="w-full flex flex-col justify-center">
                    {file && (
                      <div className="flex justify-start mb-2">
                        <div className={`relative border rounded-lg p-3 mr-1 ${theme === 'dark' ? 'border-suggestion-border text-button-background' : 'border-gray-300 text-gray-500' }`}>
                          <div className="flex items-center">
                            <FileIcon fileName={file.name} />
                            <span className="text-sm truncate ml-2 mr-1">{file.name}</span>
                            {!isUploading && (
                              <span
                                className="cursor-pointer text-xl mr-[-20px] mt-[-20px]" // Adjust these values as needed
                                onClick={handleRemoveFile}
                              >
                                <i className="fas fa-times-circle"></i>
                              </span>
                            )}
                            {isUploading && <div className="pl-1"><TailSpin height="16" width="16" color={theme === 'dark' ? 'white' : 'black'}/></div>}
                          </div>
                        </div>
                      </div>
                      )}
                    {/* Input Field */}
                    <textarea
                      value={message}
                      onChange={handleChangeHeight} // Handle change to adjust height
                      onKeyDown={handleKeyDown} // Add key down event
                      disabled={isLoading}
                      placeholder="Ask me anything ..."
                      rows={rows} // Use the dynamic rows
                      className={`w-full bg-transparent focus:outline-none ${theme === 'dark' ? 'placeholder-suggestion-decription-text' : 'placeholder-gray-500' }`}
                      style={{ resize: 'none' }} // Disable manual resizing
                    />
                  </div>
                              
                  {/* Submit Button */}
                  <button 
                    type="submit"
                    disabled={isLoading || !message.trim() || isUploading}
                    className={`p-1 rounded-xl flex items-center ml-2 mr-3 cursor-pointer ${theme === 'dark' ? 'bg-button-background text-black' : 'bg-gray-700 text-gray-100' }`}
                  >
                    <i className="fas fa-arrow-up icon-sm"></i>
                  </button>
                </div>

                {/* <div className="flex items-center justify-center py-2 px-1">
                  <span className="text-sm font-normal text-center md:font-light md:text-xs text-black dark:text-white">A.I. can make mistakes. Consider checking important information.</span>
                </div> */}
              </form>
            </div>
          </div>
          :
          <div className={`flex-1 flex flex-col md:px-3 ${theme === 'dark' ? 'bg-main-background text-white' : 'bg-gray-100 text-black'} max-w-full overflow-hidden`}>
            <div 
                className="flex items-center justify-between border-b-2 border-sidebar-background md:border-0 p-3"
                onMouseLeave={() => setIsDropdownOpen(false)}  // Clear hovered thread
              >
              <i className="fas fa-bars cursor-pointer md:hidden" onClick={() => setIsSidebarOpen(true)} ></i>
            </div>          
            
            <div className="flex-shrink-0 flex flex-1 flex-col px-1 md:px-3 py-3 md:px-8 ">           

              <div className='flex-1 flex'/>
              <span className="text-center text-black dark:text-white mb-3" style={{fontSize: '20px'}}>Choose an AI Agent to start</span>

              <div className="flex flex-col w-full px-4">
                <div className={`flex items-center py-3 px-6 border rounded-3xl ${theme === 'dark' ? 'border-suggestion-border ' : 'border-gray-200' }`}>                
                  <div className="flex flex-1 flex-col justify-center">
                    <input
                      type="text"
                      placeholder="Search agents..."
                      value={searchMainAgentKey}
                      onChange={(e) => setSearchMainAgentKey(e.target.value)}
                      className={`bg-transparent focus:outline-none ${theme === 'dark' ? 'placeholder-suggestion-decription-text' : 'placeholder-gray-500'}`}
                    />
                  </div>
                </div>

                {isAgentDropdownOpen && (
                  <div className={`absolute mt-14 2xl:w-[450px] md:w-[45vw] lg:w-[45vw] xl:w-[30vw]  max-h-36 rounded-lg overflow-y-auto ${theme === 'dark' ? 'bg-threeoptions-background' : 'bg-gray-200'}`}>
                    {filteredMainAgents.length > 0 ? (
                      filteredMainAgents.map((agent) => (
                        <div
                          key={agent.apikey}
                          className={`p-3 m-2 rounded-lg cursor-pointer flex justify-between items-center ${theme === 'dark' ? 'hover:bg-custom-hover-gray5 ' : 'hover:bg-gray-300'}`}
                          onClick={() => handleChangeAgent(agent)}
                        >
                          <div className="text-sm font-medium">
                            <p>{agent.name}</p>
                            <p className={`${theme === 'dark' ? 'text-agent-version-text' : 'text-gray-500'}`}>{agent.description}</p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="p-3 text-center">No agents found</div>
                    )}
                  </div>
                )}                
              </div>

              <div className="flex-1 flex" />

              <div className={`w-full h-48`}>
                  <Slider {...settings}>
                    {agents && agents.map(agent => {
                      return (
                        <div 
                        key={agent.apikey}
                        className={`h-48 items-center justify-center `}
                        style={{ width: 240}}>

                          <div
                            className={`flex flex-col w-52 h-48 rounded-lg transition transform duration-200 ease-in-out active:scale-95 ${agent.IsPinned === '1' ? 'bg-purple-500' : theme === 'dark' ? 'bg-threeoptions-background' : 'bg-gray-200'}`}
                            onClick={() => handleChangeAgent(agent)}
                          >
                            <div className="flex flex-col justify-start items-start mt-8 p-2">
                              <p className="text-xs font-semibold text-left pl-2 self-start mb-2">{agent.name}</p>
                              <p className={`${theme === 'dark' ? 'text-agent-version-text' : 'text-gray-500'} text-xs font-normal m-2 mt-0 inline-block self-start turncate line-clamp-2 h-12`}>
                                {agent.description}
                              </p>
                            </div>
                          </div>

                        </div>                      
                      )
                    })}
                  </Slider>
                </div>

              {/* <div className="flex items-center justify-center py-2 px-1">
                <span className="text-sm font-normal text-center md:font-light md:text-xs text-black dark:text-white">A.I. can make mistakes. Consider checking important information.</span>
              </div> */}
            </div>
          </div>      
        }      
      </div>
      {/* <SettingsModal open={isShowSettingModal} handleClose={() => setIsShowSettingModal(false)}/> */}
    </>
  );
};

export default Home;